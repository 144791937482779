<template>
  <div class="">
    <h1 class="mt-5 d-flex align-center">
      Organization Management <v-spacer></v-spacer>
      <v-btn
        color="primary"
        dark
        class="mb-2"
        elevation="0"
        :to="{ name: 'AdminOrganizationAdd', params: { id: null } }"
      >
        Add Organization
      </v-btn>
    </h1>
    <v-row class="my-8">
      <v-col class="col-12">
        <v-data-table
          :loading="tableLoading"
          loading-text="Loading Organizations... Please wait"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="serverItemsLength"
          :footer-props="{
            itemsPerPageOptions: itemsPerPage,
          }"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:top="{ pagination, options, updateOptions }">
            <v-toolbar flat class="mb-4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="fetchData"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-data-footer
                :pagination="pagination"
                :options.sync="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="itemsPerPage"
              />
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <small>{{ item.name }}</small>
              </td>

              <td>
                <small>
                  {{ formatDate(item.createdAt) }}
                </small>
              </td>

              <td class="text-center">
                <a
                  v-if="item.advisors && item.advisors.items.length > 0"
                  href="#"
                  @click.prevent="handleOpenAdvisors(item)"
                  class="text-decoration-none"
                  >View</a
                >
                <span v-else>--</span>
              </td>

              <td>
                <v-tooltip top nudge-bottom="5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="warning"
                      small
                      class="mr-2"
                      :to="{
                        name: 'AdminOrganizationEdit',
                        params: { id: item.id },
                      }"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-tooltip top nudge-bottom="5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="error"
                      small
                      @click="deleteOrganization(item)"
                    >
                      <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay :value="showOverlay" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="snackBarTimeout"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="showSnackBar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="organization"
      v-model="dialogDelete"
      persistent
      max-width="500px"
    >
      <v-card v-if="deleteOrganizationLoading">
        <v-card-text>
          <div
            class="
              h-100
              d-flex
              flex-column
              align-center
              justify-center
              pa-6
              pb-0
            "
          >
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
            <h3 class="mt-4">Deleting organization...</h3>
          </div>
        </v-card-text>
      </v-card>

      <v-card v-else>
        <v-card-title class="text-h6"
          >Are you sure you want to
          <span class="red--text">&nbsp; DELETE &nbsp; </span>
          organization:
          <small>{{ organization.name }}</small
          >?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="handleDeleteOrganization"
            >DELETE</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="organization" v-model="dialogAdvisors" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Advisors</v-card-title>
        <v-card-text>
          <v-container v-if="organization.advisors">
            <p v-for="p in organization.advisors.items" :key="p.id">
              {{ p.id }}
            </p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogAdvisors = false"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation, Storage } from "aws-amplify";

import {
  // createOrganization,
  deleteOrganization,
} from "@/graphql/mutations";
import {
  onCreateOrganization,
  onDeleteOrganization,
} from "@/graphql/subscriptions";
import tableSearchPaginateMixin from "@/mixins/tableSearchPaginateMixin";

// import { deleteCognitoUser } from "@/services/cognitoAdminQueries";

const initialOrganization = function () {
  return {
    name: null,
    logo: null,
    active: null,
    url: null,
    address: null,
    phone: null,
    advisors: null,
    resources: null,
    promos: null,
  };
};

export default {
  mixins: [tableSearchPaginateMixin],
  data() {
    return {
      queryContext: "organizations",
      queryFilter: {
        id: {
          exists: true,
        },
      },
      organization: null,
      date: null,
      showOverlay: false,
      mode: "new", // new or edit
      dialogAdvisors: false,
      dialogDelete: false,
      deleteOrganizationLoading: false,
      editedIndex: -1,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },

        {
          text: "Created",
          align: "start",
          value: "createdAt",
        },

        {
          text: "Advisors",
          value: "advisors",
          align: "center",
          sortable: false,
        },

        { text: "Actions", value: "actions", sortable: false },
      ],
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "info",
      snackBarTimeout: 3000,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    //Subscribe to changes
    API.graphql(graphqlOperation(onCreateOrganization)).subscribe(
      (sourceData) => {
        const newOrganization = sourceData.value.data.onCreateOrganization;
        if (newOrganization) {
          // skip our own mutations and duplicates
          if (this.items.some((r) => r.id == newOrganization.id)) return;
          this.items = [newOrganization, ...this.items];
        }
      }
    );
    // API.graphql(graphqlOperation(onUpdateOrganization)).subscribe((sourceData) => {
    //   const updatedOrganization = sourceData.value.data.onUpdateOrganization;
    //   if (updatedOrganization) {
    //     this.items = this.items.filter(
    //       (r) => r.id != updatedOrganization.id
    //     );
    //   }
    // });
    API.graphql(graphqlOperation(onDeleteOrganization)).subscribe(
      (sourceData) => {
        const deletedOrganization = sourceData.value.data.onDeleteOrganization;
        if (deletedOrganization) {
          this.items = this.items.filter((r) => r.id != deletedOrganization.id);
        }
      }
    );
  },

  methods: {
    async handleDeleteOrganization() {
      const organization = this.organization;
      this.deleteOrganizationLoading = true;

      if (organization) {
        // Remove promos associated with this org when deleted.
        // Delete resources associated with this org when deleted.
        // What about advisors?  Should they be deleted too? Alter the org id on them all?

        try {
          const { id } = organization;

          // const result = await deleteCognitoUser(organization);
          // console.log(
          //   "delete cognito user result",
          //   result.response.data?.message
          // );

          // if (
          //   result &&
          //   result.response &&
          //   result.response.status !== 200 &&
          //   result.response.data?.message !== "User does not exist."
          // ) {
          //   throw new Error(result.response.data.message);
          // } else if (result && result.message) {
          //   console.log(
          //     "in IF for delete cognito user result.message",
          //     result.message
          //   );

          await API.graphql(
            graphqlOperation(deleteOrganization, { input: { id: id } })
          );

          try {
            if (organization.logo && organization.logo.key) {
              await Storage.remove(organization.logo.key);
            }
          } catch (error) {
            console.log(
              "Error deleting image from S3 when deleting organization",
              error
            );
          }

          console.log("Organization deleted!");
          this.items = this.items.filter((r) => r.id != organization.id);

          this.handleSnackbar({
            isShowing: true,
            color: "success",
            text: `Organization ${organization.firstName} ${organization.lastName} has been DELETED!`,
          });

          this.closeDelete();
          // } else {
          //   throw new Error(result.response.data.message);
          // }
        } catch (error) {
          console.log("Error deleting organization...", error);
          this.organization = null;
          this.handleSnackbar({
            isShowing: true,
            color: "error",
            text: `Error Deleting organization: ${error}`,
            timeout: 10000,
          });
        } finally {
          this.deleteOrganizationLoading = false;
        }
      }
    },

    handleOpenAdvisors(item) {
      if (item) {
        this.editedIndex = this.items.indexOf(item);
        this.organization = Object.assign({}, item);
      }

      this.dialogAdvisors = true;
    },
    handleOpenVanityNames(item) {
      if (item) {
        this.editedIndex = this.items.indexOf(item);
        this.organization = Object.assign({}, item);
      }

      this.dialogVanityNames = true;
    },
    deleteOrganization(item) {
      this.editedIndex = this.items.indexOf(item);
      this.organization = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.organization = Object.assign({}, new initialOrganization());
        this.editedIndex = -1;
      });
    },
    handleSnackbar({ isShowing, color, text, timeout }) {
      this.showSnackBar = isShowing;
      this.snackBarColor = color;
      this.snackBarText = text;
      this.snackBarTimeout = timeout;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(date);
    },
  },
};
</script>

<style lang="scss">
.v-alert {
  a {
    word-break: break-all;
  }
}

pre {
  white-space: pre-line;
}
</style>
